import { FC, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserRoleContext } from '@components';
import { Role } from '../../enums/Role';
import { CircularProgress, Grid2 } from '@mui/material';

interface RoleGuardProps extends React.PropsWithChildren {
  requiredRoles: Role[];
}

export const RoleGuard: FC<RoleGuardProps> = ({ requiredRoles, children }) => {
  const userRole = useContext(UserRoleContext);
  const navigate = useNavigate();

  const [hasAccess, setHasAccess] = useState<boolean | null>(null);

  useEffect(() => {
    if (!userRole) return;
    let accessGranted = false;
    for (const neededRole of requiredRoles) {
      if ((userRole & neededRole) === neededRole) {
        accessGranted = true;
        break;
      }
    }
    setHasAccess(accessGranted);
  }, [userRole, requiredRoles]);
  useEffect(() => {
    if (hasAccess === false) navigate('/groups', { replace: true });
  }, [hasAccess, navigate]);
  // Prevent rendering before access check is complete

  if (userRole === undefined || userRole === null || hasAccess === null) {
    return (
      <Grid2 container justifyContent={'center'} alignItems={'center'}>
        <CircularProgress></CircularProgress>
      </Grid2>
    ); // Or use a proper loading spinner
  }
  return children;
};
