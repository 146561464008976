import { useRouteError } from 'react-router-dom';
import React from 'react';
import { Container, Divider, Grid, Typography } from '@mui/material';
import logger from '../utils/logger';

export const ErrorPage: React.FC<React.PropsWithChildren> = () => {
  const error = useRouteError(); // Get the error object from react-router

  // Default values
  // let status = 'Unknown Status';
  let statusText = 'An unexpected error has occurred';

  // Check if the error is an object and has the status and statusText properties (likely from react-router)
  if (error && typeof error === 'object' && 'status' in error && 'statusText' in error) {
    // status = (error as { status: number }).status.toString();
    statusText = (error as { statusText: string }).statusText;
  } else if (error instanceof Error) {
    // If it's a generic JS Error
    statusText = error.message;
  }

  logger.error(error); // Log the error

  return (
    <Container maxWidth={'md'}>
      <Grid height={'100vh'} display={'flex'} flexDirection={'column'} flex={'auto'} justifyContent={'center'}>
        <Grid
          container
          gap={2}
          borderRadius={4}
          padding={2}
          display={'flex'}
          flexDirection={'row'}
          justifyContent={'space-around'}
        >
          <Grid item display={'flex'} flexDirection={'column'} justifyContent={'center'}>
            <img alt="We Hate Exercise" height={56} src={'./logo.png'}></img>
          </Grid>
          <Divider sx={{ display: { xs: 'none', md: 'block' } }} orientation={'vertical'}></Divider>
          <Grid item display={'flex'} flexDirection={'column'} justifyContent={'space-evenly'}>
            <Typography variant={'h4'}>Oops!</Typography>
            <Typography variant={'body1'}>Sorry, an unexpected error has occurred.</Typography>
            <Typography variant={'caption'}>{statusText}</Typography>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};
