import React, { Fragment, PropsWithChildren } from 'react';
import './App.scss';

import { createTheme } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import { palette, themeOptions } from './palette';
import { Diversity1, Logout, SupervisorAccount } from '@mui/icons-material';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { AdminHome, Dashboard, ErrorPage, Groups, Home } from './pages';
import { NotRegistered } from './pages/NotRegistered';
import { AuthGuard } from './components/AuthGuard/AuthGuard';
import { AcceptInvite } from './pages/AcceptInvite';
import { TwilioClientProvider } from './TwilioClientContext';
import { UserRoleProvider } from './components/Auth/UserRoleProvider';
import { RoleBasedAccess } from './components/Auth/RoleBasedAccess';
import { useApp } from './App.hook';
import {
  AppBar,
  Avatar,
  Box,
  Button,
  Divider,
  Drawer,
  Grid2 as Grid,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  MenuList,
  PaletteMode,
  ThemeOptions,
  ThemeProvider,
  Toolbar,
  Typography,
} from '@mui/material';
import { NotificationProvider } from '@components';
import { FeedbackFish } from '@feedback-fish/react';
import { Role } from '@app/enums/Role';
import { RoleGuard } from '@components/AuthGuard/RoleGuard';

const App: React.FC<PropsWithChildren> = () => {
  const { cookies, anchorEl, menuOpen, handleClick, handleClose, logout, toggleDrawer } = useApp();

  const getDesignTokens = (mode: PaletteMode): ThemeOptions => themeOptions;
  const theme = React.useMemo(() => createTheme(getDesignTokens('light')), []);
  type NavTarget = 'ADMIN' | 'HOME' | 'OTHER';
  const router = createBrowserRouter([
    { path: '/', element: <Home />, errorElement: <ErrorPage /> },
    {
      path: '/Dashboard',
      element: (
        <AuthGuard>
          <Groups />
        </AuthGuard>
      ),
      errorElement: <ErrorPage />,
    },
    {
      path: '/Dashboard/:groupId',
      element: (
        <AuthGuard>
          <TwilioClientProvider>
            <NotificationProvider>
              <Dashboard />
            </NotificationProvider>
          </TwilioClientProvider>
        </AuthGuard>
      ),
      errorElement: <ErrorPage />,
    },

    {
      path: '/admin',
      element: (
        <UserRoleProvider>
          <AuthGuard>
            <RoleGuard requiredRoles={[Role.Admin, Role.Captain]}>
              <TwilioClientProvider>
                <AdminHome />
              </TwilioClientProvider>
            </RoleGuard>
          </AuthGuard>
        </UserRoleProvider>
      ),
      errorElement: <ErrorPage />,
    },
    {
      path: '/Groups',
      element: (
        <AuthGuard>
          <Groups />
        </AuthGuard>
      ),
      errorElement: <ErrorPage />,
    },
    { path: '/signin', element: <Home />, errorElement: <ErrorPage /> },
    {
      path: '/accept-invite',
      element: <AcceptInvite />,
      errorElement: <ErrorPage />,
    },
    {
      path: '/not-registered',
      element: <NotRegistered />,
      errorElement: <ErrorPage />,
    },
    { path: '/error', element: <ErrorPage /> },
    { path: '*', element: <ErrorPage /> },
  ]);

  const navigate = (e: any) => {
    const navTarget = e.currentTarget.value as NavTarget;
    switch (navTarget) {
      case 'ADMIN':
        window.location.href = '/admin';
        break;
      case 'HOME':
        window.location.href = '/';
        break;
      case 'OTHER':
        window.location.href = '/';
        break;
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <UserRoleProvider>
        {Boolean(cookies.auth) && (
          <AppBar
            sx={{
              backgroundColor: palette.background.default,
              marginBottom: 0,
              textAlign: 'center',
            }}
            position="sticky"
            variant={'elevation'}
            elevation={0}
          >
            <Toolbar>
              <IconButton color="primary" size="large" edge="start" onClick={() => toggleDrawer(true)}>
                <MenuIcon />
              </IconButton>
              <Drawer open={menuOpen} onClose={() => toggleDrawer(false)}>
                {cookies.profile && (
                  <Fragment>
                    <Box display="flex" flexDirection="column" padding={2} gap={2} alignItems="center" flexWrap="wrap">
                      <Avatar src={cookies.profile.picture} sx={{ height: 96, width: 96 }} />
                      <Typography variant="h3" component="div">
                        {cookies.profile.firstName}
                      </Typography>
                    </Box>
                    <Divider variant="fullWidth" color={palette.divider} />
                  </Fragment>
                )}
                <MenuList>
                  <MenuItem component="button" value="HOME" onClick={navigate}>
                    <Box display="flex" flexDirection="row" gap={2}>
                      <Diversity1 color="primary" />
                      <Typography component="span">Home</Typography>
                    </Box>
                  </MenuItem>
                  <RoleBasedAccess requiredRoles={[Role.Admin]}>
                    <MenuItem component="button" value="ADMIN" onClick={navigate}>
                      <Box display="flex" flexDirection="row" gap={2}>
                        <SupervisorAccount />
                        <Typography component="span">Admin</Typography>
                      </Box>
                    </MenuItem>
                  </RoleBasedAccess>
                </MenuList>
              </Drawer>
              <Grid component="div" sx={{ flexGrow: 1, marginTop: '10px' }}>
                <img src={'/logo.png'} alt={'We Hate Exercise'} className="logo"></img>
              </Grid>

              <span></span>

              <Fragment>
                <div className="feedbackJoyride">
                  <FeedbackFish projectId="2f352c37eef603" userId={cookies.profile.email}>
                    <Button>Feedback!</Button>
                  </FeedbackFish>
                </div>
                <Button
                  id="basic-button"
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleClick}
                >
                  <Avatar src={cookies.profile.picture}></Avatar>
                </Button>
                <Menu
                  open={Boolean(anchorEl)}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  elevation={8}
                  sx={{ backgroundColor: palette.background.border }}
                >
                  <MenuItem key="logout" onClick={logout}>
                    <ListItemIcon>
                      <Logout fontSize="small" />
                    </ListItemIcon>
                    Logout
                  </MenuItem>
                </Menu>
              </Fragment>
            </Toolbar>
          </AppBar>
        )}

        <RouterProvider router={router} />
      </UserRoleProvider>
    </ThemeProvider>
  );
};

export default App;
