import { Box, Container, Divider, Typography } from '@mui/material';
import React from 'react';

export const NotRegistered = () => {
  return (
    <Container maxWidth={'md'}>
      <Box height={'100vh'} display={'flex'} flexDirection={'column'} flex={'auto'} justifyContent={'center'}>
        <Box
          gap={2}
          borderRadius={4}
          padding={2}
          display={'flex'}
          flexDirection={'row'}
          justifyContent={'space-around'}
        >
          <Box display={'flex'} flexDirection={'column'} justifyContent={'center'}>
            <img alt="We Hate Exercise" height={56} src={'./logo.png'}></img>
          </Box>
          <Divider orientation={'vertical'}></Divider>
          <Box display={'flex'} flexDirection={'column'} justifyContent={'space-evenly'}>
            <Typography variant={'h5'}>User is not registered</Typography>
            <Typography variant={'caption'}>
              Users must be registered with We Hate Exercise to access the platform. Check back as things are always
              changing!
            </Typography>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};
